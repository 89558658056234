import { defineComponent } from 'vue';
export default defineComponent({
    name: 'OptionalAgree',
    setup() {
        const qcellsPrivacyPolicy_AU = 'https://qcells.com/au/footer/privacy-policy';
        const techSupportEmail_AU = 'qhomewarranty@qcells.com.au';
        return {
            qcellsPrivacyPolicy_AU,
            techSupportEmail_AU,
        };
    },
});
